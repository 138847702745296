import React from "react";
import Link from "next/link"

import Store from '/src/store'

import { Get } from '/src/common/components/api'

import FooterContents from '/src/layouts/blocks/footerContents'
import ContentCheck from '/src/layouts/blocks/contentCheck'


import Meta from '/src/layouts/blocks/meta'
import ImageRenderer from '/src/common/components/imageRenderer'
import Image from 'next/image'

import styles from './index.module.sass'

import UrlGenerator from '/src/common/helpers/urlGenerator'

import { withRouter } from 'next/router'

import ShopsCatsMenu from '/src/layouts/blocks/shopsCatsMenu'
import ShopsListItem from '/src/pages/shops/ShopsListItem'

export async function getServerSideProps(router) {

  if (router.query.isSSR) {
    const promos = await Get('/promos')
    const contents = await Get('/contents', {'url': '/'})
    const seos = await Get('/seos', {'url': '/'})

    return {
      props: {
        promos: promos.list,
        contents: contents,
        seos: seos,
      },
    }

  }

  return {
    props: {}
  }
}

export class Component extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      populars: [],
      sets: [],

      promos: props.promos ?? [],
      contents: props.contents ?? {},
      seos: props.seos ?? {},
    };

    if (props.promos == undefined) {
      Get('/promos', {'page_size': 3}).then(result => {
        this.setState({
          promos: result.list ?? []
        })
      }).catch(() => {

      })
    }

    if (props.contents == undefined) {
      Get('/contents', {'url': '/'}).then(result => {
        this.setState({
          contents: result ?? {}
        })
      }).catch(() => {

      })
    }

    if (props.seos == undefined) {
      Get('/seos', {'url': '/'}).then(result => {
        this.setState({
          seos: result ?? {}
        })
      }).catch(() => {

      })
    }





    Get('/shops/items/populars', {'page_size': 8}).then(result => {
      this.setState({
        populars: result.list ?? []
      })
    }).catch(() => {

    })


    Get('/shops/items/sets', {'page_size': 4}).then(result => {
      this.setState({
        sets: result.list ?? []
      })
    }).catch(() => {

    })

  }

  render() {

    return <div>





      {Meta(this.state.seos)}

      <div className={styles.banner}>
        <div className={styles.banner_wrapper}>
          <div className={`h0 ${styles.title}`}>
            Интернет-магазин оптики
            {/*Бесплатная проверка зрения*/}
          </div>

          <Link href={`/check`} className={`btn _empty _white ${styles.btn}`}>
            Записаться на проверку
          </Link>
        </div>
      </div>



      <div className={styles.cats}>
        <ShopsCatsMenu />
      </div>

      


      <div className={styles.block}>
        <div className={styles.sub}>

          <div className={styles.info_header}>
            <div className="row">
              <div className="col-md-6">
                <div className={styles.content}>
                  <div className={`h1 ${styles.title}`}>
                    Акции и скидки
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className={styles.content}>
                  <div className={styles.entry}>
                    Забота о вашем зрении - это забота о вашем здоровье и комфорте. Если вы замечаете, что зрение стало менее четким, испытываете усталость глаз или возникли другие
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className={styles.promos}>

            <div className={styles.list}>

              {this.state.promos.map((promo, key) => {

                return <Link href={`/promos/${promo.alias}`} className={styles.item} key={key}>
                  <div className={styles.image}>
                    {promo.images && promo.images[0] && <Image
                        src={promo.images[0].image}
                        width={promo.images[0].width}
                        height={promo.images[0].height}
                        alt={promo.title}
                      />
                    }
                  </div>
                </Link>

                return <div className={styles.item} key={key}>
                </div>
              })}

            </div>
          </div>

        </div>





        


        {this.state.populars.length > 0 && <div className={styles.sub}>

          <div className={styles.info_header}>
            <div className="row">
              <div className="col-md-6">
                <div className={styles.content}>
                  <div className={`h1 ${styles.title}`}>
                    Популярные товары
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className={styles.content}>
                  <div className={styles.entry}>
                    Забота о вашем зрении - это забота о вашем здоровье и комфорте. Если вы замечаете, что зрение стало менее четким, испытываете усталость глаз или возникли другие
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="shops_list">

            <div className="row">

              {this.state.populars.map((shopsItem, key) => {
                return <div className={`col-xs-6 col-sm-4 col-md-4 col-lg-3`} key={key}>
                  <ShopsListItem shopsItem={shopsItem}/>
                </div>
              })}

            </div>

          </div>


        </div>}





        

        








        

        {/*<div className={styles.sub}>

          <div className={styles.info_header}>

            <div className="row">
              
              <div className="col-md-6">

                <div className={styles.content}>

                  <div className={`h1 ${styles.title}`}>
                    Очки с доставкой по Санкт-Петербургу
                  </div>

                </div>

              </div>
              
              <div className="col-md-6">
                <div className={styles.content}>
                  <div className={styles.entry}>
                    Забота о вашем зрении - это забота о вашем здоровье и комфорте. Если вы замечаете, что зрение стало менее четким, испытываете усталость глаз или возникли другие
                  </div>
                </div>
              </div>

            </div>

          </div>







          <div className={styles.advantages}>

            <div className={styles.list}>
              <div className={styles.item}>
                <div className={styles.image}>
                  <img src="/template/demo/advantage-1.svg" alt="" />
                </div>
                <div className={styles.text}>
                  Работаем в 83-х регионах России
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.image}>
                  <img src="/template/demo/advantage-1.svg" alt="" />
                </div>
                <div className={styles.text}>
                  50 000+ товаров для здоровья и красоты
                </div>
              </div>

              <div className={styles.item}>
                <div className={styles.image}>
                  <img src="/template/demo/advantage-1.svg" alt="" />
                </div>
                <div className={styles.text}>
                  Привезём в аптеку или доставим домой от 30 минут
                </div>
              </div>
            </div>
          </div>



        </div>*/}














        

        <div className={styles.sub}>

          <div className={styles.info_header}>

            <div className="row">
              <div className="col-md-6">
                <div className={styles.content}>
  {/*                <Link href="/news" className={styles.more}>
                    Смотреть
                  </Link>*/}
                  
                  <div className={`h1 ${styles.title}`}>
                    Подборки товаров
                  </div>

                </div>
              </div>
              <div className="col-md-6">
                <div className={styles.content}>
                  <div className={styles.entry}>
                    Забота о вашем зрении - это забота о вашем здоровье и комфорте. Если вы замечаете, что зрение стало менее четким, испытываете усталость глаз или возникли другие
                  </div>
                </div>
              </div>
            </div>



          </div>

          <div className={styles.sets}>

            <div className={styles.list}>

              {this.state.sets.map((set, key) => {

                return <div className={styles.item} key={key}>

                  <Link href={UrlGenerator({set: [set.alias]})} className={styles.inner}>
                    {set.images && set.images[0] && <Image
                        src={set.images[0].image}
                        width={set.images[0].width}
                        height={set.images[0].height}
                        alt={set.title}
                      />
                    }
                  </Link>

                  <Link href={UrlGenerator({set: [set.alias]})} className={styles.text}>
                    {set.title}
                  </Link>
                </div>

              })}

            </div>
          </div>

        </div>

        {FooterContents(this.state.contents)}

      </div>


      

    </div>
  }

}


function WithRouter({ router, promos, contents, seos }) {
  return <Component
    router={router}
    promos={promos}
    contents={contents}
    seos={seos} />
}

export default withRouter(WithRouter)
